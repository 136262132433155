// import { useNavigate } from "react-router-dom";

import PlutoLogo from "../../assets/Logo.png";
import FullscreenLogo from "../../assets/FullScreen.png";

import "./styles.css";

type PropType = { 
  onFSClick?: React.MouseEventHandler 
};

const DefaultProp = { 
  onFSClick: ()=>{}
};

export const Header = ({ onFSClick }: PropType = DefaultProp) => {
  // const navigate = useNavigate();

  return (
    <div className="header">
      <img onClick={() => window.open("/","_self")} src={PlutoLogo} alt="pluto"/>
      {onFSClick && <img onClick={onFSClick} src={FullscreenLogo} alt="fullscreen"/>}
    </div>
  );
};
