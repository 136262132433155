import mixpanel from 'mixpanel-browser';
import type { MetaMaskInpageProvider } from "@metamask/providers";

export const useMetaMask = () => {
  const ethereum = global?.window?.ethereum;
  if (!ethereum || !ethereum.isMetaMask) return;
  return ethereum as unknown as MetaMaskInpageProvider;
};

export const isMetaMaskInstalled = () => {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
}

export const trackMixpanel = (event: string, eventData: Object = {}) =>{
  mixpanel.init('3bd915b1be983220ee9ac1e7c6773fc6', {debug: true}); 
  mixpanel.track(event, eventData);
}
