import { useEffect /*, useState*/, useState } from "react";
import { trackMixpanel } from "../../hooks/utils";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
//import PinkBtn from "../../components/PinkBtn";
import NormalBtn from "../../components/NormalBtn";
// import { useConnectWallet } from "../../hooks/connectWallet";
import "./styles.css";
// import { CustomPopup } from "../../components/Popup";
import { Header } from "../../components/Header";
//import useWindowDimensions from "../../hooks/useWindowDimensions";
//import { json } from "stream/consumers";
// const apiPath = process.env.REACT_APP_API_URL + "/v1";
import { isMobile } from "react-device-detect";


function LandingPage() {
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let navigate = useNavigate();
  // const [gameID, setGameID] = useState('');
  // const [open, setOpen] = useState(false);
  // const [showError, setShowError] = useState(false);

  let canplay = false;

  useEffect(() => {
    trackMixpanel("LandingPage");
  }, []);
  // useEffect(()=> { if(gameID!=='') navigate(`/start/${gameID}`)},[gameID, navigate]);
  // const { address, connecting, connectWallet, connectDifferentWallet } = useConnectWallet();

  // const connectWalletFn = async () => {
  //     setOpen(true);
  //     await connectDifferentWallet();
  //     console.log('Done')
  //     await registerSession(address);
  //     setTimeout(()=>{
  //         setOpen(false);
  //         setShowError(false);
  //     }, 1000);
  // }

  // const registerSession = async (accountAddress: string) => {
  //     try {
  //         console.log(`Register Session For ${accountAddress}`)
  //         const response = await axios({
  //             url: `${apiPath}/create`,
  //             method: 'POST',
  //             data: { address: accountAddress },
  //             withCredentials: true
  //         });
  //         const data = response.data;

  //         if(data && data.success === true) {
  //             setGameID(data.gameId);
  //         } else {
  //             alert(data.message);
  //             setShowError(true);
  //         }
  //     } catch(err) {
  //         const error = err as AxiosError;
  //         const data = (error.response?.data) as { status: boolean; message: string};
  //         setShowError(true);
  //         // alert(data?.message || 'Oops something went wrong!');
  //         // setUseDifferentWallet(true);
  //     }
  // }


  //const { width } = useWindowDimensions();
  //const isMobile = width < 480;
  return (
    <div className="">
      <Header />

      <div className="relative">
        <div>
          {isMobile ? (
            // <div className="q2-card-wrapper">
            //   <div className="q2-card-mobile">
            //     <div className="q2-card-essence-mobile">
            //       <div className="q2-header-mobile">Message from Q2</div>
            //       <div className="q2-mobile"></div>
            //       <div className="q2-body-mobile">
            //         {MOBILE_ERROR_TEXT.map((_) => (
            //           <div className="q2-card-text-mobile">{_}</div>
            //         ))}
            //       </div>
            //     </div>
            //   </div>
            // </div>

            <div className="container relative mx-auto md:-mt-[70px]">
              <div className="flex relative justify-center">
                <div className="absolute z-10">
                  <img src="/Q2_Box_Mobile.png" alt="" />
                </div>

                <div className="flex flex-col items-center text-left mt-[70px]">
                  <div className="z-20 w-full px-[20px]">
                    <div className="text-[22px]  w-full font-bold border-b-[1.5px] py-[5px] border-dashed border-[#0A0909]  border-t-[1.5px]">
                      <span>Message from Q2</span>
                    </div>
                  </div>
                  <img
                    className="z-30 max-w-[190px] max-h-[280px] mt-[20px]"
                    src="/Q2.png"
                    alt=""
                  />
                  <div className="z-20 mt-[20px] max-w-[300px] text-left px-[20px] space-y-[10px]">
                    <div className="">Thank you Misfits, for taking one last ride with me!</div>
                    <div className="">An evolution is about to happen.</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="relative lg:container mx-auto flex items-center w-full h-full justify-center mt-[30px] lg:mt-[60px]  xl:mt-[10px] md:overflow-hidden lg:overflow-visible md:py-[100px] lg:py-0 md:px-[20px] lg-px-0">
                <img
                  className=" hidden md:block absolute md:max-w-full lg:max-w-[1100px] z-10"
                  src="/Q2_Box.png"
                  alt=""
                />
                <img
                  className="absolute md:hidden lg:max-w-[260px] lg:max-h-[280px] md:max-w-[190px] md:max-h-[280px]"
                  src="/Q2_Box_Mobile.png"
                  alt=""
                />

                <div className="z-20 h-full mt-[40px] md:mt-12 px-[50px] max-w-[350px] md:max-w-none">
                  <div className="flex flex-col md:flex-row text-left items-center ">
                    <div className="">
                      <img
                        className="z-30 max-w-[200px]  md:mt-0 lg:max-w-[260px] lg:max-h-[280px] md:max-w-[190px] md:max-h-[280px]"
                        src="/Q2.png"
                        alt=""
                      />
                    </div>

                    <div className=" md:ml-[35px] mt-[20px] md:mt-0 z-20 ">
                      <p className="text-[18px] md:text-[22px] lg:text-[26px] font-bold border-b-[1.5px] md:border-b-[3px] py-[10px] border-dashed border-[#0A0909] border-t-[1.5px]  md:border-t-[3px]">
                        <span>Message from Q2</span>
                      </p>
                      <div
                        id="q2-body"
                        className="lg:text-[20px] text-[14px] sm:text-[16px] overflow-auto max-w-[600px] max-h-[150px] md:max-h-[200px] mt-[20px]"
                      ><div className="">Thank you Misfits, for taking one last ride with me!</div>
                        <div className="">An evolution is about to happen.</div>
                      </div>
                    </div>
                  </div>
                  <div className="md:border-b-[3px]  border-dashed border-[#0A0909] md:border-t-[3px] border-t-[1.5px] border-b-[1.5px]  py-[10px] md:py-[20px] mt-[20px] bottom-0">
                    <button
                      onClick={() => { window.location.href = 'https://discord.gg/plutomisfits' }}
                      className="md:px-[70px]  px-[30px] z-40 cursor-pointer hover:bg-[#FFC068] py-[10px] lg:py-[20px] text-[16px] md:text-[22px] font-bold bg-[#FED294] rounded-[22px] hover:text-black  border-b-4 border-r-4 border-[#404040]"
                    >
                      Follow the journey
                    </button>
                    {/* <NormalBtn onClickFn={requestAuth} text="Connect Discord" /> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
