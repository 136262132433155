import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './pages/Landing';
import './App.css';
//import Game from './pages/Game';
//import Privacy from './pages/Privacy';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        {/*<Route path="/play" element={<Game />} />*/}
        {/*<Route path="/privacy" element={<Privacy />} />*/}
      </Routes>
    </div>
  );
}

export default App;
